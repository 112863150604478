export const skills = [
  "JavaScript",
  "React",
  "Ruby on Rails",
  "S3, CloudWatch, Lambda",
  "AWS CDK",
  "Perl",
];

export const socialMedia = [
  {
    name: "GitHub",
    url: "https://github.com/fevazquez",
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/fernando-4-vazquez/",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/itsfernanflow/",
  },
];

export const jobs = [
  {
    title: "Software Development Engineer II",
    company: "Amazon Web Servies",
    abbreviation: "AWS",
    date: "September 2022 - Present",
    description: "I work on the Infrastructure Foundational Services team. We are focused on writing modern and scalable systems to automate the provisioning and maintenance of Amazons physical server fleet. We operate across all regions in every AWS partition.",
    url: "https://aws.amazon.com/",
  },
  {
    title: "DevOps Engineer",
    company: "Nisum",
    abbreviation: "Nisum",
    date: "September 2021 - August 2022",
    description:
      "During my time here, I wrote modern and robust code for a diverse array of internal projects using React, Javascript, Docker, K8s and Springboot. I also setup CI/CD pipelines for a variety of development and production environments using Jenkins.",
    url: "https://www.nisum.com/",
  },
  {
    title: "Research Assistant",
    company: "Scripps Orbit and Permanent Array Center",
    abbreviation: "SOPAC",
    date: "June 2019 - September 2021",
    description:
      "Built and maintained a web application to improve and expand working with large GPS and seismic data sets including real-time collection, quality control, analysis, display, and documentation.",
    url: "http://sopac-csrc.ucsd.edu/",
  },
  {
    title: "Student Avionics Engineer",
    company: "Rocket Propulsion Laboratory",
    abbreviation: "RPL",
    date: "October 2018 - June 2019",
    description:
      "Over the course of a year, I worked with 3 undergraduates to redesign, test, and implement Avionics devices to construct an engine controller unit, GPS, and a command/recovery system. We produced a BJT transistor array PCB to properly toggle relays and an INA326 instrumentation amplifier array PCB to amplify pressure transducer readings.",
    url: "https://rocketproplab.org/",
  },
];

export const projects = [
  {
    title: "Basic Computer Processing Unit",
    summary:
      "Constructed a basic pipelined CPU using Verilog that would execute basic arithmetic algorithms to support a self designed ISA",
    technologies: ["Intel Quartus Prime", "Verilog", "ModelSim"],
    img: "https://lh3.googleusercontent.com/pw/AM-JKLVWoMEvC63A546GFFlHP9qrAp_Ox52TgoV-PIxuPaqrdIucXQyrAVuYiOpnd13FMf9w7BBx_ulxXpgXT40z41q6cZDFgjlOGwf_lckk-qj5D-QyCzartWb27cs_qRV_yHXMcQcpykeAmMTn6-WWq18=w1678-h1208-no?authuser=1",
    mobile_img:
      "https://lh3.googleusercontent.com/pw/AM-JKLUbN_FYbrOycQbXxfoCI-EDr_2TfGXAI97QiAuAy7u4MOshrzCiqGGHDSdgedUuvwUHPVo-44mhO2yx5e_IkrU0Lqhst7h-AsQkhZGToCXd39c6wKb5SMGtG3QgM5nA91YojNQtjpnmkazlmfopZ4I=w1678-h1035-no?authuser=1",
    link: "https://github.com/fevazquez/Pipelined-CPU",
  },
  {
    title: "Flow Tab Manager Chrome Extension",
    summary:
      "Worked with a team to develop an open-source Chrome extension to manage tabs for productivity purposes",
    technologies: ["React", "Node.js", "Circle CI"],
    img: "https://lh3.googleusercontent.com/pw/AM-JKLXpj2xtFxs1Ctw6l8H6dnP3sPOHro1Q6D3YQzcICXU26kDNLBPZJ006nYg9TrgLiCnXzUv-pvt-GwZx8gnRJl_hWoSUt2qUPB7cHMc0v_aELrddaaxmvekTbgJLLcYcS7jo368g7SRCkI_DlwhV-Zk=w1508-h986-no?authuser=1",
    mobile_img:
      "https://lh3.googleusercontent.com/pw/AM-JKLX9XbRE6yC3xSCeLxC8t7lnj_s7JLkWksn54WZ0PvilhYkHZVWf84CtLD0zGSldYFLWr5kViI4xut5eNT_Q7mKW1EjcP5xCFvfiBy8alK49ruyU8E9mgEbwKaWpiDcVTReKx7MPAF_jrCSjbhaUQh8=w1273-h796-no?authuser=1",
    link: "https://github.com/cse112-sp20/Team-Potato",
  },
  {
    title: "Try Clubs Mobile Application",
    summary:
      "Worked with a team to develop a mobile application for finding and advertising student organizations",
    technologies: ["Java", "Android Studio", "Gradle"],
    img: "https://lh3.googleusercontent.com/pw/AM-JKLV3f_9kPjsvHvNpmRIeXqP4Wrfhikfn8eRa7PT2hq2UUeFiPkEBNP9m2m1lzB7uawuida24Ug1g6yksLPB0u7ld6xTTop6v6SGo1QTQ6b-Pwza3pKXwxHhs_obOnsbIXN12-d_DSo6I64sPJ-IKETw=w1678-h1086-no?authuser=1",
    mobile_img:
      "https://lh3.googleusercontent.com/pw/AM-JKLWA18Wu2wNu0wBy5DKT9L9Ga6iUvm0sfJKd1tq4f8X6m3DWY0tik5n1s89JV79tdWWrsw6JJ0fIGx1EQ5SmTZfdlzcNZf-cnTjCdY7wqzFkGV0dPjDsqfdSzOny0vjhTjSWTfsmLlKKX4y6WD6ujAc=w1678-h825-no?authuser=1",
    link: "https://github.com/jamesbasa/TryClubs",
  },
];
